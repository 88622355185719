<template>
  <section>
    <ContentCard data-auto-id="realms-no-content" class="text-center">
      <div class="p-12">
        <img
          src="@/assets/empty-office.svg"
          alt="illustration of an empty office"
          class="inline-block mb-10"
        />
        <h3 class="text-sm mb-6 text-semibold">Create a Realm</h3>
        <p>
          Realms are the main building blocks of identity management with Tozny.
          Create a realm then configure to allow easy registration and login all
          with end to end encryption by default.
        </p>
        <p class="mb-8">
          There is no limit to the number of realms you can have.
        </p>
        <RealmForm
          inline
          @submit="createRealm"
          :loading="loading"
          :error="errorMessage"
          class="max-w-3/4 mx-auto"
        />
      </div>
    </ContentCard>
  </section>
</template>

<script>
import ContentCard from '../../../../Common/ContentCard'
import RealmForm from './RealmForm'
export default {
  name: 'NoTokens',
  components: {
    ContentCard,
    RealmForm,
  },
  props: {
    createRealm: Function,
    loading: Boolean,
    errorMessage: String,
  },
}
</script>
