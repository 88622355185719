<template>
  <ToznyForm
    :on-submit="handleSubmit"
    :error="errorText"
    :loading="loading"
    class="text-left"
  >
    <div :class="inlineClasses">
      <ToznyInput
        data-auto-id="new-realm-name"
        v-model="providedName"
        :class="inlineInputClass"
        class="w-full"
        name="realmName"
        id="realmName"
        label="Realm Name"
        placeholder="AcmeLLC"
        :float-label="inline"
      />
      <div class="flex flex-row-reverse items-center">
        <ToznyButton
          data-auto-id="new-realm-submit"
          class="m-0"
          buttonText="Create Realm"
          :loading="loading"
        />
        <a
          href="#!"
          v-if="!inline"
          @click.prevent="$emit('cancel')"
          class="text-gray-darkest no-underline mr-8"
          >Cancel</a
        >
      </div>
    </div>
  </ToznyForm>
</template>

<script>
import ToznyForm from '../../../../Common/ToznyForm'
import ToznyButton from '../../../../Common/ToznyButton'
import ToznyInput from '../../../../Common/ToznyInput'
export default {
  name: 'RealmForm',
  components: {
    ToznyForm,
    ToznyButton,
    ToznyInput,
  },
  props: {
    loading: Boolean,
    error: String,
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      providedName: '',
      errorText: this.error,
    }
  },
  computed: {
    inlineClasses() {
      return this.inline ? 'flex justify-center' : ''
    },
    inlineInputClass() {
      return this.inline ? 'mr-4' : 'mb-8'
    },
  },
  methods: {
    handleSubmit() {
      if (this.providedName) {
        this.providedName = this.providedName.trim()
        // limit identity to 30 characters alphanumeric
        const re = new RegExp('^[a-zA-Z0-9]{1,30}$')
        if (re.test(this.providedName)) {
          this.$emit('submit', this.providedName)
        } else {
          this.errorText =
            'Realm name should be between 1-30 alphanumeric characters'
        }
      } else {
        this.errorText = 'Realm name required.'
      }
    },
  },
}
</script>

<style></style>
